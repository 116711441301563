@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.alert {
  position: fixed !important;
  bottom: 10px;
  float: right;
  right: 10px;
  box-shadow: 0px 0px 8px 0px;
  z-index: 9;
}

.alert-danger {
  z-index: 100000000000 !important;
}

.pagination_container {
  margin-top: 20px;
}

.pagination {
  justify-content: flex-end;
}

.pagination li {
  margin-left: 4px;
  padding: 4px 0px;
}

.pagination li a {
  text-decoration: none;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
}

.pagination li:first-child a,
.pagination li:last-child a {
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.pagination li:nth-child(2) a,
.pagination li:nth-last-child(2) a {
  background-color: rgb(100 43 115);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}


.invalid-feedback {
  display: block !important;
}


.load {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  text-align: center;
  background-color: rgb(255 255 255 / 90%);
  z-index: 9;
}

.load img {
  position: fixed;
  left: calc(50% - 50px);
  top: calc(50% - 60px);
  -webkit-animation: zoom-in-zoom-out 1s ease infinite;
          animation: zoom-in-zoom-out 1s ease infinite;
}


.react-confirm-alert-body {
  /* font-family: Arial, Helvetica, sans-serif; */
  width: 400px;
  padding: 30px;
  text-align: left;
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%) !important;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgb(0 0 0 / 13%);
  color: #ffffffe0 !important;
  font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #2b00806b !important;
  border: none;
  display: inline-block;
  padding: 5px 18px !important;
  color:#ffff !important;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 18px !important;
  cursor: pointer;
}
.react-confirm-alert-button-group button {
  background-color: #f8f9fa !important;
  color: #662f71 !important;
}
@-webkit-keyframes zoom-in-zoom-out {
  0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
  }

  50% {
      -webkit-transform: scale(0.8, 0.8);
              transform: scale(0.8, 0.8);
  }

  100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
  }
}
@keyframes zoom-in-zoom-out {
  0% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
  }

  50% {
      -webkit-transform: scale(0.8, 0.8);
              transform: scale(0.8, 0.8);
  }

  100% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
  }
}

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}



.Dashboard{
    padding: 20px 0px;
}

.dashboard_tab {
    justify-content: space-between;
}

.dashboard_tab li {
    width: calc(25% - 15px);
    margin-bottom: 30px;
}
.dashbox {
    border-radius: 0.25rem;
    box-shadow: 0 0 1px rgb(0 0 0 / 13%), 0 1px 3px rgb(0 0 0 / 20%);
    cursor: pointer;
}


.dash-1 {
    background-color: rgb(23 162 184);
}

.dash-2{

    background-color: rgb(40 167 69);
}


.dash-3{
    background-color: rgb(255 193 7);
}


.dash-top{
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.dash-left {
    color: rgb(255, 255, 255);
}

.dash-right {
    text-align: center;
    color: rgba(0, 0, 0, .15);
    font-size: 70px;
}




.display-date ul{
    display: flex;
    list-style-type: none;
    justify-content: space-between;
}


.dash-count{
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 10px;
    font-family: 'Teko', sans-serif;
    line-height: 45px;
}


.dash-users{
    font-size: 20px;
    line-height: 18px;

}

.dash-right{
    
        text-align: center;
        color: rgba(0, 0, 0, .15);
        font-size: 70px;
    
}

.dash-right i {
    transition: 0.5s all ease-in-out;
}


.dashbox.pink {
    background:#dc3545;
   
}
.dashbox.green {
    background: #17a2b8;
}

.dashbox.blue {
    background:  #28a745;
}

.dashbox.yellow{
    background: #ffc107;
}

.dashbox.yellow .dash_icon span {
    color:#ffc107;
}

.dashbox {
    color: #fff;
    border-radius: 8px;
    padding: 25px 11px;
    text-align: center;
    box-shadow: 0 0 10px rgb(85 85 85 / 60%);
    display: flex;
    align-items: center;
}

.dash_icon {
    width: 70px;
    margin-right: 15px;
}


.dashbox.pink .dash_icon span {
    color: rgb(225 53 69);
}


.dashbox.green .dash_icon span {
    color: rgb(0 163 183);
}

.dashbox.blue .dash_icon span {
    color: rgb(20 165 78);
}
.dash_icon span {
    background-color: rgb(255 255 255);
    height: 75px;
    width: 75px;
    display: inline-block;
    line-height: 78px;
    border-radius: 50%;
    font-size: 30px;
}

.dashbox_cont {
    width: calc(100% - 85px);
    text-align: left;
}

.dashtag {
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    border-bottom: 1px solid #fff;
    margin-bottom: 15px;
    padding-bottom: 15px;
}

.dashtag1{
    color:  rgb(17, 12, 12);
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
    border-bottom: 1px solid rgb(17, 12, 12);
    margin-bottom: 15px;
    padding-bottom: 15px;

}

.dash_number1{
    color:  rgb(17, 12, 12);
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}

.dash_number {
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
}

button.btn2 {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    background: rgb(100 43 115);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
    margin-left: 4px;
}


button.btn1 {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    background: rgb(100 43 115);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
   
}

.page_head span{
    font-size: 1px;
    font-size: 13px;
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    padding: 5px 5px;
    border-radius: 5px;
    color: #FFFFFF;
    text-transform: capitalize;
    margin-left: 13px;
}
.showrec{
    align-items: center;
}

.DatePick span{
    /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
    color: rgb(90, 0, 68);
    margin-right: 5px;
    /* border: 1px solid ; */
    border-radius: 5px;
}

/* .DatePick{
    border: 1px solid rgb(100 43 115);
} */
.DatePick {
    margin-bottom: 3px;
}
body {
    font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.flt {
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.header_container {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
    border-bottom: 1px solid #642B73;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    width: 10%;
}
.menuH {
    padding: 0px !important;
    margin: 0px !important;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.menuH li {
    list-style-type: none;
    display: inline-block;
    padding: 0px 15px;
    position: relative;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.menuH li a {
    font-size: 12px !important;
    font-weight: 600;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s all ease-in-out;
}
.menuH li .dropdown li {
    float: left;
    width: 100%;
}
.menuH li .dropdown li a {
    float: left;
    width: 100%;
    padding: 10px 0px;
}
.menuH li .dropdown {
    padding: 0px;
    margin: 0px;
    position: absolute;
    top: 30px;
    left: -32px;
    width: 170px;
    background-color: rgb(255 255 255);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 40%);
    text-align: left;
    border-radius: 3px;
    border-bottom: 2px solid rgb(90, 0, 68);
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
    transition: 0.5s all ease-in-out;
    opacity: 0;
    visibility: hidden;
}
.menuH li:hover .dropdown {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
    transition: 0.5s all ease-in-out;
    opacity: 1;
    visibility: visible;
    z-index: 9;
}
.header .row {
    align-items: center;
}

.header_text {
    text-align: center;
    padding: 10px 0px;
    text-transform: uppercase;
    color: #00415A;
}

.header_menu ul {
    list-style-type: none;
    display: flex;
}

.header {
    padding: 10px 20px;
    border-bottom: 2px solid #F1AAAA;
}

.header_menu ul li {

    text-decoration: none;
}

.logout{
    text-decoration: none;
    border-radius: 4px;
    height: 30px;
    width: 31px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

  body{
      font-family: 'Zen Kaku Gothic New', sans-serif !important;
  }
  
  .flt{
      float: left;
      width: 100%;
  }
  ul {
      margin: 0px;
      padding: 0px;
  }


.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


/* .login_page {
  background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
  height: 100vh;
  position: relative;
}
.login_box {
  margin: 0% auto;
  background-color: rgb(255, 255, 255);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 4px 10px rgb(32 32 32);
  width: 75%;
  border-radius: 10px;
}
.login_head {
  font-size: 33.81px;
  font-weight: 500;
  color: #003f7d;
  margin-bottom: 40px;
  font-family: "Montserrat";
  font-style: normal;
  line-height: 41px;
  padding-left:5px;
}

.user_id {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}
.password {
  float: left;
  font-family: "Montserrat";
  font-size: 17px;
  color: #012348;
  font-weight: 500;
  padding-bottom: 15px;
}

.login_box .form-group {
  margin-bottom: 20px;
  position: relative;
}

.login_box .form-group::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  left: 15px;
  top: 70%;
  z-index: 9;
  transform: translateY(-50%);
  font-size: 18px;
  color: #ff8e01;
}

.login_box .form-group:first-child::before {
  content: "\f007";
}
.login_box .form-group:last-child::before {
  content: "\f084";
}
.login_box input {
  padding: 12px 10px 12px 50px;
  border-bottom: 1px solid #dddddd;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  width: 100%;
  margin-bottom: 3px;
   font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;


}
.login_btn {
  margin: 25px 0px 10px 0px;
}

.login_btn {
  text-align: right;
}
.login_btn button {
  font-size: 15px;
  background: #003466;
  color: rgb(255, 255, 255) !important;
  border: none;
  text-transform: uppercase;
  padding: 15px 50px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.version {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 13.5225px;
  color: #003f7d;
  float: left;
}

.login_left {
  box-shadow: 0px 0px 20px rgba(0, 12, 116, 0.2);
  border-radius: 10px 0px 0px 10px;
  margin: 5px 3px;
  padding: 25px 0px;
}
.login_left img {
  width: 100%;
}

.login_right {
  padding: 4% 8%;
}

.login_box .row {
  align-items: center;
}

.required {
  padding: 5px 0px;
}
/* .fa-play:before {
  content: "\F04B";
  padding-left: 17px;
  background-color: #003F7D;
} */

/* @media (max-width: 767px) {
  .login_btn {
    text-align: center;
    margin: 20px 0px;
  }
  .login_page {
    background: linear-gradient(to bottom, #003466 50%, #003f7d 50%);
    height: 100vh;
    position: relative;
    padding: 20px;
    overflow: auto;
  }
  .login_box {
    width: 90%;
  }
  .login_head {
    font-size: 30px;
    text-align: center;
  }
  .mobile_logo {
    width: auto !important;
  }
  .login_left {
    box-shadow: none;
    border-radius: 0px;
    margin: 0px;
    padding: 30px 0px 10px 0px;
    text-align: center;
  }
} */

body{
    font-family: 'Zen Kaku Gothic New', sans-serif !important;
}

.flt{
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.logo img{
    width: 55px;
}
.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}
.login_box {

  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
  padding: 55px;
  float: left;
  width: 500px;
  border-bottom: 3px solid rgb(90, 0, 68);

}
.login_logo {
  text-align: center;
}
.login_head {
  padding: 20px;
  text-align: center;
}
.login_box label {
  color: rgba(102, 102, 102, 1);
  float: left;
  width: 100%;
  font-size: 14px;
}
.login_box input {
  color: rgb(0,0,0);
  font-size: 15px;
  height: 45px;
  background-color: rgb(245 245 245);
}


.login_head {
  padding: 20px;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #642B73;
  text-transform: uppercase;
}


.btn button {
  background: linear-gradient(268.13deg, #642B73 8.91%, #C6426E 51.58%);
  border-radius: 4px;
  padding: 8px 45px;
  color: #FFFFFF;
  border: none;
  font-size: 15px;
}



 
.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}


/* 
.card h2 {
  color: #7a447a;
  padding: 20px;
}

.card {
  box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
  transition: 0.3s;
  padding: 0px 30px;
} */

/* On mouse-over, add a deeper shadow */
/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

/* Add some padding inside the card container */
/* 
.card ul {
  list-style-type: none;
  text-align: left;
}

.card li {
  text-decoration: none;
  padding: 5px;
  color: rgb(100 43 115);

} */

.back_button {
  margin-top: 20px;
  text-align: right;
}

.back_button a {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.styuser_list img {
  width:100%;
}


.styuser_list1 {
  display: flex;
}

.user_list2 {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}







.styuser_list {

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}


.styuser_list1 {

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 20px;
}


.styldetails2 {
  font-size: 14px;
  /* line-height: 27px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #000000;

}

.styldetails3 {
  font-size: 14px;
  /* line-height: 27px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;

}

ul.styldetails2 {
  list-style-type: none;
}

.styhead {
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color:rgb(100 43 115);
  text-transform: uppercase;
}

/* .user_list li {
  list-style-type: none;

  color: rgb(0,0,0);
 
  margin-bottom: 15px;
} */


.styldetails2 span {
  word-break: break-word;
}
.viewuser_profile {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  text-align: center;
  padding: 20px;
  border-radius: 4px;
}

.viewuser_profile img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid rgb(201 201 201);
}

.video_display {
  width: 30%;
  height: 280px;
}

/* .col-sm-6.topv.mb-4 {
  margin-top: -265px;
} */


.profpic {

  padding: 0px 80px;
}

.profpic img {
  width: 70%;
}


.view-container {
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
}

/* .backbtn button {
  float: right;
  margin: 0px 60px;
  border: none;
  border-radius: 3px;
  background: transparent;
  color: #ffff;
} */


/* .col-sm-4 {

  line-height: 45px;
} */
.back_button1 button {
  border: none;
  float: left;
  /* border-radius: 6px; */
  color: rgb(100 43 115);
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  /* padding: 5px 20px; */
  /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
  margin-bottom: 10px;
  background: none;
}

/* .backbtn button {
  margin: -65px 48px;
  border: none;
  float: right;
  border-radius: 6px;
  color: white;
  padding: 5px 20px;
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
} */

.stylehead {
  font-size: 26px;
  font-weight: 500 !important;
  color: rgb(100 43 115);
  text-transform: uppercase;
  padding: 15px;
}

.promvideo {
  margin-top: 25px;
}

.text1 {
  font-size: 20px;
  margin-top: 20px;
}

li.promvideo {
  margin-top: 0px;
}


/* .stylimg img {
  object-fit: cover;
} */
.footer {
    background: rgb(51 51 51);
    padding: 10px;
}

.foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}


.flt {
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 5px;
    color: rgb(255, 255, 255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
}

.reject {
    background-color: rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view {
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}

.table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}

.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}

.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}

.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}

.stylish_top {
    margin-bottom: 20px;
}

.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}

.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}

.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}

.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}
.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}


.page-container{
align-items: center;}

.Card-container{
    
    text-align: center;
}

.card h2 {
    color: #7a447a;
    padding: 20px;
}

.card {
    box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
    transition: 0.3s;
    padding: 0px 30px;
}
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */

.card ul{
    list-style-type: none;
    text-align: left;
}
  .card li
  {
    text-decoration: none;
    padding: 5px;
    color: rgb(100 43 115);
    
  }

  .back_button {
    margin-top: 10px;
    text-align: right;
}
.back_button a {
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.user_list img{
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  text-align: left;
}


.page_head {
  text-align: left;
  color: rgb(100 43 115);
  padding: 10px;
}

.styldetails3
{


  font-size: 14px;
    /* line-height: 27px; */
    font-weight: 400;
    font-size: 14px;
    line-height: 31px;
    color: #000000;
    text-align: left;
    list-style-type: none;
}

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}
.fashion_image {
    max-height: 60px;
}

.fashion_left {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
    padding: 15px;
    border-radius: 4px;
}
.fashion_left input {
    height: 45px;
}
.fashion_left label {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    float: left;
    width: 100%;
}
.fashion_submit {
    margin-top: 10px;
}
.fashion_submit button {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
    padding: 5px 30px;
    border-radius: 4px;
    font-weight: 600;
}

.fashion_left input, .fashion_left select {
    font-size: 15px;
    color: rgb(0, 0, 0);
}
.fashion_right table td button.edit {
    background-color: rgb(40 167 69);
    border: 1px solid rgb(0 128 0);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: rgb(255,255,255);
    font-size: 13px;
    margin-right: 5px;
}
.fashion_right table td button.delete {
    background-color: rgb(241 52 52);
    border: 1px solid rgba(255, 0, 0, 0.998);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: rgb(255,255,255);
    font-size: 13px;
}
.favourite_preview img {
   
    margin-top: 10px;
    border: 1px solid rgb(206 212 218);
    border-radius: 4px;
}
.fashion_submit {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fashion_right {
    position: relative;
}
.table_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 4px;
}

.favourite_preview img {
    max-width: 100%;
}



.upload_btn{
    position: relative;
    padding: 8px 20px;
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    overflow: hidden;
    color: #ffff;
    border-radius: 5px;
    font-size: 14px;
    border: 0;
    cursor: pointer;
    }
    
    .upload_btn input[type="file"] { 
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        -webkit-transform: scale(3);
                transform: scale(3);
        opacity: 0;
    }
.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.back_button {
  margin-top: 20px;
  text-align: right;
}

.back_button a {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.user_list img {
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
}
.user_list li {
  list-style-type: none;
  width: 100%;
  color: rgb(0,0,0);
  float: left;
  margin-bottom: 15px;
}

.user_list li b {
  width: 35%;
}
.viewfash_profile {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  text-align: center;
  padding: 20px;
  border-radius: 4px;
}
.viewfash_profile img {
 
  border-radius: 1px !important;
  border:0;
}

.styhead2{
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  color:rgb(100 43 115);
  text-transform: uppercase;
}

.styuser_list2{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_list2 img{
  width:20%;
  height: 20%;
}

.fash_img img {
  max-width: 100%;
  /* height: 114px; */
}
.fashion_view_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 10px;
}

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}


.fashion_submit button {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
    padding: 5px 30px;
    border-radius: 4px;
    font-weight: 600;
    margin-right: 15px;
}

.table_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 4px;
}
.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}


.page-container{
align-items: center;}

.Card-container{
    
    text-align: center;
}

.card h2 {
    color: #7a447a;
    padding: 20px;
}

.card {
    box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
    transition: 0.3s;
    padding: 0px 30px;
}
  
  /* On mouse-over, add a deeper shadow */
  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  
  /* Add some padding inside the card container */

.card ul{
    list-style-type: none;
    text-align: left;
}
  .card li
  {
    text-decoration: none;
    padding: 5px;
    color: rgb(100 43 115);
    
  }

  .back_button {
    margin-top: 10px;
    text-align: right;
}
.back_button a {
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.user_list img{
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  text-align: left;
}
/* .user_list li {
  list-style-type: none;
  width: 100%;
  color: rgb(0,0,0);
  float: left;
  margin-bottom: 15px;
}
.user_list li span {
  float: left;
  width: 60%;
}
.user_list li b {
  float: left;
  width: 40%;
} */


.page_head {
  text-align: left;
  color: rgb(100 43 115);
  padding: 10px;
}




.styldetails4{
  text-align: left;
  list-style-type: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #000000;

}
.footer {
  background: rgb(51 51 51);
  padding: 10px;
}

.foot_text {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  text-transform: uppercase;
  color: #FFFFFF;
  text-align: center;
}


.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.videoupl {
  box-shadow: 0 0px 4px rgb(105 105 105 / 50%);
  background-color: rgba(245, 245, 245, 1);
  padding: 20px;
  /* margin-bottom: 25px; */
  width: 55% !important;
  margin: 30px 0px 0px 55px;
}


.page_container {
  padding: 40px 20px;
  min-height: calc(100vh - 117px);
}



.header_menu {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}



.page_head {
  font-size: 26px;
  font-weight: 500 !important;
  color: rgb(90, 0, 68);
  text-transform: uppercase;
}

.Card-container {

  text-align: center;
}

.card h2 {
  color: #7a447a;
  padding: 20px;
}



.card {
  box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.Video {
  padding: 40px;
}

.video-head h2 {
  color: rebeccapurple;
}

.back_button {
  text-align: left !important;
  /* text-align: right; */
}

.back_button button {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}


.With-head {
  padding: 10px;
  text-align: left;
  color: rgb(100 43 115);
}

.videoupl {
  text-align: left;
  padding: 30px 8px;
}

.Video video {
  width: 100%;
}

.videoupl input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 4px;
  font-size: 14px;
}
.flt{
    float:left;
    width:100%;
}

.page_container{
    padding:40px 20px;
    min-height: calc(100vh - 117px);
}

.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}

.stylish-table .table-responsive{
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}

.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}

.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.news_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}
.news_desc {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
}

.news_action{
    width:120px;
}

.image{
    width:80px;
}

.card{
    padding:2px;
}
.form_details{
    font-size: 15px;
}
.form_details input{
   width:250px;
   height:45px;
}
.form_details label{
    margin-top:2px;
    
}

.form_details .textarea{
    width:250px;
    margin-bottom: 5px;
}
textarea.form-control {
    height: 150px;
}

.form-group label b{
    font-size: 18px;
}
.upbtn{
    padding: 5px 22px;
    position: relative;
    overflow: hidden;
    background: #ffff;
    border: 0;


}

/* .upbtn i
{
font-size: 25px;
}

input[type="file"] 
{
position: absolute;
left: 0px;
cursor: pointer;
transform: scale(3);
opacity: 0;
} */

.image_preview img{
    width: 60%;
    margin-top: 10px;
    border: 1px solid rgb(206 212 218);
    border-radius: 4px;
}

.news_submit {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.news_submit button {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
    padding: 5px 30px;
    border-radius: 4px;
    font-weight: 600;
}

@-webkit-keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }

@keyframes spinner {
    0% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg);
    }
  }
.loading-spinner {
    width: 50px;
    height: 50px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    -webkit-animation: spinner 1.5s linear infinite;
            animation: spinner 1.5s linear infinite;
  }

  .spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 350px;
  }  
  .search_bar1 {
    margin-bottom: 10px;
}

.newsform {
    box-shadow: 0 0px 4px rgb(105 105 105 / 50%);
    background-color: rgba(245, 245, 245, 1);
    padding: 20px;
    margin-bottom: 25px;
}
.newsform input[type="file"] {
    padding: 3px;
}

.crop_btn button {
    background-color: rgb(100 43 115);
    text-decoration: none;
    padding: 5px 15px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(255 255 255);
}
.crop_box {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.crop_image {
    width: 48%;
}
.crop_image img {
    width: 100%;
    border: 1px solid rgb(100 43 115);
}


.upload_btn{
position: relative;
padding: 8px 20px;
background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
overflow: hidden;
color: #ffff;
border-radius: 5px;
font-size: 14px;
border: 0;
}
.upload_btn span {
    margin-left: 6px;
    letter-spacing: 2px;
}

.newsform input[type="file"] { 
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    -webkit-transform: scale(3);
            transform: scale(3);
    opacity: 0;
}
.flt{
  float:left;
  width:100%;
}

.user_list img {
width: 60px;
height: 60px;
}
.user_list {
box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
border-radius: 4px;
padding: 15px;
}
.user_list li {
list-style-type: none;

color: rgb(0,0,0);

margin-bottom: 28px;
}



.user_list li b {
float: left;
font-size: 16px;

}

.news_image {
box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
text-align: center;
padding: 20px;
border-radius: 4px;
}
.news_image img {
width: 250px;
height: 250px;
border-radius: 0%;
border: 1px solid rgb(201 201 201);
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; 
  border-top: 10px solid #383636; 
  border-radius: 50%;
  -webkit-animation: spinner 1.5s linear infinite;
          animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 350px;
}  

.likes i {
  color: purple;
}


.styuser_list11{

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 20px;


}

.titledesc{
  font-size: 14px;
  line-height: 28px;
}


.titledesc b{

  font-weight: 400;
  font-size: 20px;
  color: #92278F;
}

/* .styuser_list b{
  font-weight: 400;
font-size: 20px;
line-height: 31px;
color:  rgb(100 43 115);
}

.styuser_list span{
font-size: 14px;
}

.styuser_list11 span{
font-size: 14px;
}
.styuser_list11 b{
  font-weight: 400;
font-size: 20px;
line-height: 31px;
color:  rgb(100 43 115);
} */

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}

.faq-image img{
    width: 50px;
    height: 50px;
}
.favourite_preview img {
  
    margin-top: 10px;
    border: 1px solid rgb(206 212 218);
    border-radius: 4px;
}

.default_img {
    width: 35px;
    height: 35px;
}

.table_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 4px;
}
.faqdefault_img{
    width: 60px;
    height: 60px;
}


.no_data {
    text-align: center;
    padding: 30px 0px;
    font-size: 20px;
    font-weight: 500;
}
.upload_btn{
    position: relative;
    padding: 8px 20px;
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    overflow: hidden;
    color: #ffff;
    border-radius: 5px;
    font-size: 14px;
    border: 0;
    }
    
    .upload_btn input[type="file"] { 
        position: absolute;
        cursor: pointer;
        left: 0;
        top: 0;
        -webkit-transform: scale(3);
                transform: scale(3);
        opacity: 0;
    }

.footer {
    background: rgb(51 51 51);
    padding: 10px;
}
  .foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
  }


.flt{
    float: left;
    width: 100%;
}
ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active{
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color:rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255,255,255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view{
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}
  .table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}
.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}
.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}
.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}
.stylish_top {
    margin-bottom: 20px;
}
.page1_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}
.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}
.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}


.fashion_left {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
    padding: 15px;
    border-radius: 4px;
}
.fashion_left input {
    height: 45px;
}
.fashion_left label {
    font-size: 18px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    float: left;
    width: 100%;
}
.fashion_submit {
    margin-top: 10px;
}
.fashion_submit button {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255,255,255);
    border: 1px solid transparent;
    padding: 5px 30px;
    border-radius: 4px;
    font-weight: 600;
}

.fashion_left input, .fashion_left select {
    font-size: 15px;
    color: rgb(0, 0, 0);
}
.fashion_right table td button.edit {
    background-color: rgb(40 167 69);
    border: 1px solid rgb(0 128 0);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: rgb(255,255,255);
    font-size: 13px;
    margin-right: 5px;
}
.fashion_right table td button.delete {
    background-color: rgb(241 52 52);
    border: 1px solid rgba(255, 0, 0, 0.998);
    width: 28px;
    height: 28px;
    border-radius: 4px;
    color: rgb(255,255,255);
    font-size: 13px;
}
.favourite_preview img {
    
    margin-top: 10px;
    border: 1px solid rgb(206 212 218);
    border-radius: 4px;
}
.fashion_submit {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.fashion_right {
    position: relative;
}
.table_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 60%);
    border-radius: 4px;
}
.page1_head .lowercase {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: lowercase;
}
.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

.back_button {
  margin-top: 20px;
  text-align: right;
}

.back_button a {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.user_list img {
  width: 60px;
  height: 60px;
}
.user_list {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
}
.user_list li {
  list-style-type: none;
  color: rgb(0,0,0);
  margin-bottom: 15px;
}


.viewuser_profile {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  text-align: center;
  padding: 20px;
  border-radius: 4px;
}
.viewuser_profile img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid rgb(201 201 201);
}
.footer {
    background: rgb(51 51 51);
    padding: 10px;
}

.foot_text {
    font-weight: 500;
    font-size: 12px;
    line-height: 21px;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.sdefault_img {
    height: 60px;
}


.flt {
    float: left;
    width: 100%;
}

ul {
    margin: 0px;
    padding: 0px;
}

.stylish_tab .active {
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
}

.stylish_tab li button {
    text-decoration: none;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background-color: transparent;
}

.approve {
    background-color: #28a745;
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255, 255, 255);
    border: 1px solid #008000;
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.reject {
    background-color: rgb(241 52 52);
    border-radius: 4px;
    margin-right: 2px;
    color: rgb(255, 255, 255);
    border: 1px solid rgba(255, 0, 0, 0.998);
    font-size: 14px;
    padding: 2px 10px;
    font-weight: 500px;
}

.view {
    border: none;
    background: none;
}


.page_container {
    padding: 40px 20px;
    min-height: calc(100vh - 117px);
}

.table_cont {
    padding: 10px 10px;
}


.header_menu {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
}


.stylish_table .table-responsive {
    box-shadow: 0 0 15px 0 rgb(18 131 214 / 15%);
}

.stylish_table table tbody tr:nth-child(odd) {
    background-color: rgba(245, 245, 245, 1);
}

.stylish_table table th {
    font-size: 15px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    line-height: 20px;
    border-bottom: 1px solid rgb(90, 0, 68) !important;
    border-top: none;
    vertical-align: middle;
}

.stylish_table table td {
    font-size: 14px;
    color: rgb(0, 0, 0);
    font-weight: 400;
    border: none;
    vertical-align: middle;
}

.stylish_top {
    margin-bottom: 20px;
}

.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}

.stylish_table table td .fa-times {
    color: rgb(220 53 69);
    font-size: 13px;
}

.stylish_tab li {
    list-style-type: none;
    display: inline-block;
    margin-right: 10px;
}

.stylish_tab li a {
    text-decoration: none;
    padding: 4px 10px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
}


.news_title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}
.flt {
    float: left;
    width: 100%;
  }
  
  ul {
    margin: 0px;
    padding: 0px;
  }
  
  
  /* 
  .card h2 {
    color: #7a447a;
    padding: 20px;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
    transition: 0.3s;
    padding: 0px 30px;
  } */
  
  /* On mouse-over, add a deeper shadow */
  /* .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  } */
  
  /* Add some padding inside the card container */
  /* 
  .card ul {
    list-style-type: none;
    text-align: left;
  }
  
  .card li {
    text-decoration: none;
    padding: 5px;
    color: rgb(100 43 115);
  
  } */
  
  .back_button {
    margin-top: 20px;
    text-align: right;
  }
  
  .back_button a {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
  }
  
  .user_list img {
    width: 60px;
    height: 60px;
  }
  .user_list {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    border-radius: 4px;
    padding: 15px;
  }
  .user_list li {
    list-style-type: none;
  
    color: rgb(0,0,0);
   
    margin-bottom: 15px;
  }
  
  .viewuser_profile {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    text-align: center;
    padding: 20px;
    border-radius: 4px;
  }
  .viewuser_profile img {
    width: 250px;
    height: 250px;
    border-radius:0%;
    border: 1px solid rgb(201 201 201);
  }
  
  .video_display {
    width: 100%;
    height: 315px;
  }

  .underline{
    border-top: 1px solid rgb(100 43 115);
    margin-top: 20px;

  }


  .basicuser_list{
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    border-radius: 4px;
    padding: 15px;
    line-height: 35px;
  }

  .basicuser_list img {
    width: 100%;
    height: 230px;
}

  .styldetails2 span {
    word-break: break-word;
}


.flt {
    float: left;
    width: 100%;
  }
  
  ul {
    margin: 0px;
    padding: 0px;
  }
  
  .back_button {
    margin-top: 20px;
    text-align: right;
  }
  
  .back_button a {
    text-decoration: none;
    padding: 6px 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid rgb(100 43 115);
    color: rgb(100 43 115);
    background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
    color: rgb(255, 255, 255);
    border: 1px solid transparent;
  }
  
  .buser_list img {
    width: 60px;
    height: 60px;
  }
  .buser_list {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    border-radius: 4px;
    padding: 15px;
  }
  .buser_list li {
    list-style-type: none;
    color: rgb(0,0,0);
    margin-bottom: 15px;
    line-height: 35px;
  }
  
  .viewuser_profile {
    box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
    text-align: center;
    padding: 20px;
    border-radius: 4px;
  }
  .viewuser_profile img {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 1px solid rgb(201 201 201);
  }

  .blogimg img {
    width: 100%;
    height: 50%;
}


.back_button1 i {
  font-size: 17px;
}

.styuser_listbl1{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl1{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl2{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 15px;
}
.styuser_listbl3{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}
.styuser_listbl4{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}

.styuser_listbl5{
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 15px;
}


.titledesc b{

  font-weight: 400;
  font-size: 20px;
  color: #92278F;
}

.flt{
    float:left;
    width:100%;
}

.page_container{
    padding:40px 20px;
    min-height: calc(100vh - 117px);
}

.page_head {
    font-size: 26px;
    font-weight: 500 !important;
    color: rgb(90, 0, 68);
    text-transform: uppercase;
}
