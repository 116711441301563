.flt {
  float: left;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}


/* 
.card h2 {
  color: #7a447a;
  padding: 20px;
}

.card {
  box-shadow: 0 4px 8px 0 rgb(139 22 150 / 20%);
  transition: 0.3s;
  padding: 0px 30px;
} */

/* On mouse-over, add a deeper shadow */
/* .card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
} */

/* Add some padding inside the card container */
/* 
.card ul {
  list-style-type: none;
  text-align: left;
}

.card li {
  text-decoration: none;
  padding: 5px;
  color: rgb(100 43 115);

} */

.back_button {
  margin-top: 20px;
  text-align: right;
}

.back_button a {
  text-decoration: none;
  padding: 6px 30px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid rgb(100 43 115);
  color: rgb(100 43 115);
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
  color: rgb(255, 255, 255);
  border: 1px solid transparent;
}

.styuser_list img {
  width:100%;
}


.styuser_list1 {
  display: flex;
}

.user_list2 {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  margin-top: 15px;
}







.styuser_list {

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
}


.styuser_list1 {

  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  border-radius: 4px;
  padding: 15px;
  line-height: 35px;
  margin-top: 20px;
}


.styldetails2 {
  font-size: 14px;
  /* line-height: 27px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;
  color: #000000;

}

.styldetails3 {
  font-size: 14px;
  /* line-height: 27px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 31px;

}

ul.styldetails2 {
  list-style-type: none;
}

.styhead {
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  color:rgb(100 43 115);
  text-transform: uppercase;
}

/* .user_list li {
  list-style-type: none;

  color: rgb(0,0,0);
 
  margin-bottom: 15px;
} */


.styldetails2 span {
  word-break: break-word;
}
.viewuser_profile {
  box-shadow: 0px 0px 6px rgb(151 151 151 / 48%);
  text-align: center;
  padding: 20px;
  border-radius: 4px;
}

.viewuser_profile img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid rgb(201 201 201);
}

.video_display {
  width: 30%;
  height: 280px;
}

/* .col-sm-6.topv.mb-4 {
  margin-top: -265px;
} */


.profpic {

  padding: 0px 80px;
}

.profpic img {
  width: 70%;
}


.view-container {
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
}

/* .backbtn button {
  float: right;
  margin: 0px 60px;
  border: none;
  border-radius: 3px;
  background: transparent;
  color: #ffff;
} */


/* .col-sm-4 {

  line-height: 45px;
} */
.back_button1 button {
  border: none;
  float: left;
  /* border-radius: 6px; */
  color: rgb(100 43 115);
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  /* padding: 5px 20px; */
  /* background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%); */
  margin-bottom: 10px;
  background: none;
}

/* .backbtn button {
  margin: -65px 48px;
  border: none;
  float: right;
  border-radius: 6px;
  color: white;
  padding: 5px 20px;
  background: linear-gradient(268.13deg, rgb(100 43 115) 8.91%, rgb(198 66 110) 51.58%);
} */

.stylehead {
  font-size: 26px;
  font-weight: 500 !important;
  color: rgb(100 43 115);
  text-transform: uppercase;
  padding: 15px;
}

.promvideo {
  margin-top: 25px;
}

.text1 {
  font-size: 20px;
  margin-top: 20px;
}

li.promvideo {
  margin-top: 0px;
}


/* .stylimg img {
  object-fit: cover;
} */